<template>
    <card>
        <form @submit.prevent="guardarPlantillaRuta">
            <div class="row mt-4 align-items-center">
                <div class="col-xl-6 mb-3">
                    <div>
                        <label for="lugarEntrega" class="form-label">
                            <em class="text-danger font-weight-bolder">Paso 1</em> - Lugar de Entrega
                        </label>
                        <input v-model="plantillaRuta.LugarEntrega" type="text" id="lugarEntrega" class="form-control"
                            required>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div>
                        <label for="Ubicacion" class="form-label">
                            <em class="text-danger font-weight-bolder">Paso 2</em> - Zona
                        </label>
                        <input v-model="ubicacionInput" type="text" id="Ubicacion" class="form-control" required>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div>
                        <label for="latUbicacionEntrega" class="form-label">
                            <em class="text-danger font-weight-bolder">Paso 3</em> - Latitud
                        </label>
                        <input v-model="plantillaRuta.LatUbicacionEntrega" type="text" step="any"
                            id="latUbicacionEntrega" class="form-control" required>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div>
                        <label for="longUbicacionEntrega" class="form-label">
                            <em class="text-danger font-weight-bolder">Paso 4</em> - Longitud
                        </label>
                        <input v-model="plantillaRuta.LongUbicacionEntrega" type="text" step="any"
                            id="longUbicacionEntrega" class="form-control" required>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <vs-button :loading="isCreating" type="submit" animation-type="vertical">
                    Guardar Plantilla de Ruta
                    <template #animate> <i class="fas fa-plus-circle"></i></template>
                </vs-button>
            </div>
        </form>
        <DataTableButtons :items="plantillasRutas" :headers="headerPlantillaPuntosEntrega" :keys="plantillasRutasKeys"
            :actions1="showNotaAnexo" button1="fa-solid fa-pen" titleButton1="Editar punto de entrega"
            tableId="datatable_PlantillaRutaModal" />


        <b-modal v-model="showModal" title="Detalle Punto de Entrega">
            <div>
                <div class="mb-3">
                    <label for="lugarEntregaModal" class="form-label">Lugar de Entrega</label>
                    <input v-model="modalData.lugarEntrega" type="text" id="lugarEntregaModal" class="form-control">
                </div>
                <div class="mb-3">
                    <label for="latitudModal" class="form-label">Latitud</label>
                    <input v-model="modalData.latUbicacionEntrega" type="text" id="latitudModal" class="form-control">
                </div>
                <div class="mb-3">
                    <label for="longitudModal" class="form-label">Longitud</label>
                    <input v-model="modalData.longUbicacionEntrega" type="text" id="longitudModal" class="form-control">
                </div>
                <div class="mb-3">
                    <label for="ubicacionModal" class="form-label">Ubicación</label>
                    <input v-model="modalData.ubicacion" type="text" id="ubicacionModal" class="form-control">
                </div>
            </div>
            <template #modal-footer>
                <vs-button :loading="isUpdating" @click="actualizarPlantillaRuta(modalData.id)">
                    Actualizar Plantilla
                    <template #animate> <i class="fas fa-save"></i></template>
                </vs-button>
            </template>
        </b-modal>
    </card>
</template>

<script>
import DataTableButtons from "../../../components/Datatable/DatatableButtons.vue"

export default {
    name: 'PlantillaRutaModal',
    components: {
        DataTableButtons
    },
    data() {
        return {
            isCreating: false,
            ubicacionInput: '',
            plantillaRuta: {
                LugarEntrega: '',
                Ubicacion: '',
                Estado: '1',
                LatUbicacionEntrega: null,
                LongUbicacionEntrega: null,
                EmpresaId: null
            },
            plantillasRutas: [],
            headerPlantillaPuntosEntrega: ["Nombre Punto", "Latitud", "Longitud", "Zona"],
            plantillasRutasKeys: ["lugarEntrega", "latUbicacionEntrega", "longUbicacionEntrega", "ubicacion"],
            modalData: {
                lugarEntrega: '',
                latUbicacionEntrega: '',
                longUbicacionEntrega: '',
                ubicacion: '',
            },
            showModal: false,
        }
    },
    computed: {
        capitalizedUbicacion() {
            return this.ubicacionInput.split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        }
    },
    watch: {
        capitalizedUbicacion(newValue) {
            this.plantillaRuta.Ubicacion = newValue;
        }
    },
    async mounted() {
        const persona = JSON.parse(localStorage.getItem("setPersonaAct"));
        this.plantillaRuta.EmpresaId = persona.empresa.id;
        await this.getPuntosEntregaData();
    },
    methods: {
        async guardarPlantillaRuta() {
            try {
                this.isCreating = true;
                // Ensure the Ubicacion is capitalized before sending
                this.plantillaRuta.Ubicacion = this.capitalizedUbicacion;
                const respuesta = await this.$store.dispatch("hl_post", {
                    path: "EntregasApp/CreatePlantillaRutas",
                    data: this.plantillaRuta
                });
                if (respuesta.success) {
                    this.$vs.notification({
                        progress: "auto",
                        icon: `<i class='fas fa-circle-check'></i>`,
                        color: 'success',
                        position: 'top-right',
                        title: 'Éxito',
                        text: 'Plantilla de ruta guardada correctamente'
                    });
                    this.$emit('plantilla-ruta-agregada');
                    this.$bvModal.hide('modalPlantillaRuta');
                    // Clear the form after successful submission
                    this.resetForm();
                }
            } catch (error) {
                console.error("Error al guardar la plantilla de ruta:", error);
                this.$vs.notification({
                    progress: "auto",
                    icon: `<i class='fas fa-exclamation-circle'></i>`,
                    color: 'danger',
                    position: 'top-right',
                    title: 'Error',
                    text: 'No se pudo guardar la plantilla de ruta'
                });
            } finally {
                this.isCreating = false;
            }
        },
        async getPuntosEntregaData() {
            try {
                const path = `EntregasApp/GetPlantillasRutasByEmpresa/${this.plantillaRuta.EmpresaId}`;
                const response = await this.$store.dispatch("get", { path });
                if (response && response.data) {
                    this.plantillasRutas = response.data;
                } else {
                    console.error("Error al obtener las plantillas de entrega. Respuesta vacía.");
                    alert("Error al obtener las plantillas de entrega.");
                }
            } catch (error) {
                console.error("Hubo un error al hacer la solicitud:", error);
                alert("Hubo un error al obtener las plantillas de entrega.");
            }
        },
        async getGetPlantillasRutasById(id) {
            try {
                const path = `EntregasApp/GetPlantillasRutasById/${id}`;
                const response = await this.$store.dispatch("get", { path });
                if (response && response.data) {
                    console.log("Esta es la DATA", response)
                    this.detalleModal = {
                        LugarEntrega: response.data.lugarEntrega,
                        LatUbicacionEntrega: response.data.latUbicacionEntrega,
                        LongUbicacionEntrega: response.data.longUbicacionEntrega,
                        Ubicacion: response.data.Ubicacion,
                    };
                } else {
                    console.error("Error: Respuesta vacía al obtener detalle.");
                    this.$vs.notification({
                        color: "danger",
                        title: "Error",
                        text: "No se pudo obtener el detalle del punto de entrega.",
                    });
                }
            } catch (error) {
                console.error("Error al obtener detalle:", error);
            }
        },
        resetForm() {
            this.ubicacionInput = '';
            this.plantillaRuta = {
                LugarEntrega: '',
                Ubicacion: '',
                Estado: '1',
                LatUbicacionEntrega: null,
                LongUbicacionEntrega: null,
                EmpresaId: this.plantillaRuta.EmpresaId
            };
        },
        async showNotaAnexo(itemId) {
            try {
                const path = `EntregasApp/GetPlantillasRutasById/${itemId}`;
                const response = await this.$store.dispatch("get", { path });

                if (response && response.data && response.data.length > 0) {
                    this.modalData = response.data[0]; // Asegúrate de asignar el primer elemento
                    this.showModal = true; // Muestra el modal
                } else {
                    console.error("Datos no encontrados");
                }
            } catch (error) {
                console.error("Error al obtener los datos para el modal:", error);
            }
        },

        async actualizarPlantillaRuta(Id) {
            try {
                this.isCreating = true;
                // Asegúrate de que la Ubicación esté correctamente capitalizada antes de enviarla
                this.modalData.Ubicacion = this.capitalizedUbicacion;

                // Realizar la llamada PUT usando el Vuex action 'hl_put'
                const respuesta = await this.$store.dispatch("hl_put", {
                    path: `EntregasApp/UpdatePlantillaRuta/${Id}`, // Asegúrate de que la URL sea correcta
                    data: this.modalData, // Los datos que se envían en la petición
                });

                // Si la respuesta es exitosa, muestra una notificación
                if (respuesta.success) {
                    this.$vs.notification({
                        progress: "auto",
                        icon: `<i class='fas fa-circle-check'></i>`,
                        color: 'success',
                        position: 'top-right',
                        title: 'Éxito',
                        text: 'Plantilla de ruta actualizada correctamente'
                    });
                    this.$emit('plantilla-ruta-agregada'); // Emitir un evento para actualizar la vista
                    this.$bvModal.hide('modalPlantillaRuta'); // Cerrar el modal
                    this.resetForm(); // Limpiar el formulario
                }
            } catch (error) {
                console.error("Error al actualizar la plantilla de ruta:", error);
                this.$vs.notification({
                    progress: "auto",
                    icon: `<i class='fas fa-exclamation-circle'></i>`,
                    color: 'danger',
                    position: 'top-right',
                    title: 'Error',
                    text: 'No se pudo actualizar la plantilla de ruta'
                });
            } finally {
                this.isCreating = false;
            }
        },

    }
}
</script>

<style scoped>
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>