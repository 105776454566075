<template>
    <div class="container my-4">
        <div class="card mb-4 shadow-lg selector-card">
            <div class="card-body p-4">
                <span class="my-2 h2 margin-bottom-10"><b>¿Cómo desea añadir los puntos de entrega?</b></span>
                <div class="d-flex justify-content-between">
                    <div class="selection-card-wrapper">
                        <div class="selection-card" @click="modoSeleccion = 'manual'"
                            :class="{ 'selected': modoSeleccion === 'manual' }">
                            <div class="card-content">
                                <div class="icon-wrapper">
                                    <i class="fas fa-hand-pointer fa-2x"></i>
                                </div>
                                <h3 class="text-lg font-semibold mt-3 mb-2">Manual</h3>
                                <span class="my-2 h5 margin-bottom-10"><b>Seleccione y ordene los puntos de entrega
                                        individualmente.</b></span>
                            </div>
                        </div>
                    </div>
                    <div class="selection-card-wrapper">
                        <div class="selection-card" @click="modoSeleccion = 'zonas'"
                            :class="{ 'selected': modoSeleccion === 'zonas' }">
                            <div class="card-content">
                                <div class="icon-wrapper">
                                    <i class="fas fa-map-marked-alt fa-2x"></i>
                                </div>
                                <h3 class="text-lg font-semibold mt-3 mb-2">Por Zonas</h3>
                                <span class="my-2 h5 margin-bottom-10"><b>Seleccione una zona predefinida para cargar
                                        puntos de entrega.</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-light rounded mb-4 d-flex justify-content-center align-items-center" style="height: 400px;">
            <iframe class="rounded" :src="iframeSrc" style="border:0; width: 100%; height: 100%" allowfullscreen=""
                loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div class="card mb-4">
            <div class="card-body">
                <span class="h5 font-weight-bold">Añadir Coordenadas Iniciales</span>
                <div class="mb-3 d-flex flex-column">
                    <label for="latitud" class="form-label">Latitud</label>
                    <input id="latitud" type="text" class="form-control" v-model="originLatCoodenades"
                        placeholder="Ingrese latitud" />
                </div>
                <div class="mb-3 d-flex flex-column">
                    <label for="longitud" class="form-label">Longitud</label>
                    <input id="longitud" type="text" class="form-control" v-model="originLongCoodenades"
                        placeholder="Ingrese longitud" />
                </div>
            </div>
        </div>

        <div v-if="modoSeleccion === 'manual'">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="mb-3 d-flex flex-column">
                        <span class="h3">Añadir puntos de entrega</span>
                        <MultiSelect v-model="plantillasElegidas" :options="plantillasRutas" valorGuardar="id"
                            valorVer="lugarEntrega" emptyList="¡Lo sentimos!. No tienes plantillas creadas."
                            :selectFirst="false" :allData="true" />
                    </div>
                    <div class="d-flex justify-content-between">
                        <button @click="limpiarTodasLasRutas()" class="btn btn-danger">Limpiar todas las rutas</button>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="modoSeleccion === 'zonas'">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="mb-3 d-flex flex-column">
                        <span class="h3 mb-3">Seleccionar zona</span>
                        <v-select required class="style-chooser" v-model="zonaSeleccionada" :options="zonas"
                            placeholder="-- selecciona una zona --"></v-select>
                    </div>
                    <div class="mb-3 d-flex flex-column">
                        <span class="h3">Añadir puntos de entrega</span>
                        <MultiSelect v-model="plantillasElegidas" :options="plantillasRutas" valorGuardar="id"
                            valorVer="lugarEntrega" emptyList="¡Lo sentimos!. No tienes plantillas creadas."
                            :selectFirst="false" :allData="true" />
                    </div>
                    <div class="d-flex justify-content-between">
                        <button @click="limpiarTodasLasRutas()" class="btn btn-danger">Limpiar todas las rutas</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="alert alert-dark text-center font-weight-bold" role="alert" v-if="plantillasElegidas.length != 0">
            <span>Puntos de entrega totales: {{ plantillasElegidas.length }}</span>
        </div>

        <div class="card">
            <form ref="form">
                <draggable v-model="plantillasElegidas" @start="drag = true" @end="drag = false" v-bind="dragOptions"
                    handle=".handle" :disabled="apiExecuted">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <li v-for="(point, index) in puntosDeEntrega" :key="index"
                            class="list-group-item d-flex align-items-center justify-content-between route-point">
                            <div class="timeline-wrapper">
                                <div class="timeline-line"></div>
                                <div class="point-number handle" :class="{ 'disabled': apiExecuted }">
                                    <span>{{ index + 1 }}</span>
                                </div>
                            </div>
                            <span class="col-2">{{ point.lugarEntrega }}</span>
                            <div class="col-8 bg-dark p-3 rounded " style="height: 15vh !important">
                                <div class="d-flex flex-row h-100 justify-content-between">
                                    <div class="d-flex flex-column justify-content-center">
                                        <label class="d-flex font-weight-bold flex-wrap">Distancia:</label>
                                        <p class="font-weight-normal">
                                            {{ estimacionesTiempos[index]?.distancia || 'No consultado' }}</p>
                                    </div>
                                    <div class="d-flex flex-column justify-content-center">
                                        <label class="d-flex font-weight-bold flex-wrap">Tiempo (con tráfico):</label>
                                        <p class="font-weight-normal">{{ estimacionesTiempos[index]?.duracionConTrafico
                                            || 'No consultado' }}</p>
                                    </div>
                                    <div class="d-flex flex-column justify-content-center">
                                        <label class="d-flex font-weight-bold flex-wrap">Hora estimada de
                                            llegada:</label>
                                        <p class="font-weight-normal">
                                            {{ estimacionesTiempos[index]?.horaEstimada || 'No consultado' }}</p>
                                    </div>
                                    <div class="d-flex flex-column justify-content-center" v-if="apiExecuted">
                                        <label class="d-flex font-weight-bold flex-wrap">Programar hora de
                                            llegada:</label>
                                        <input :id="'deliveryDateTime' + index"
                                            @change="actualizarFechaHoraEntrega(index, $event.target.value)"
                                            type="datetime-local" class="form-control" required>
                                    </div>
                                </div>
                            </div>
                            <vs-button v-if="!apiExecuted" @click.prevent="removePuntoEntrega(index, $event)"
                                class="text-danger bg-danger">
                                <i class="fa-solid fa-trash"></i>
                            </vs-button>
                        </li>
                    </transition-group>
                </draggable>
                <div class="d-flex justify-content-around mt-4">
                    <vs-button v-if="!apiExecuted" @click.prevent="getUbicationApi()" class="text-white bg-primary">
                        <i class="fa-solid fa-location-crosshairs mr-2"></i>
                        Obtener tiempos aproximados
                    </vs-button>
                    <vs-button v-if="apiExecuted" @click="saveData()" class="text-white bg-success" type="submit">
                        <i class="fa-solid fa-truck mr-2"></i>
                        Guardar puntos de entrega
                    </vs-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
export default {
    name: 'PlanificadorRutas',
    components: {
        MultiSelect,
        draggable,
        vSelect
    },
    props: {
        rutaIdObtener: String
    },
    data() {
        return {
            modoSeleccion: 'manual',
            zonaSeleccionada: null,
            zonas: [],
            puntosDeZona: [],
            plantillasRutas: [],
            plantillasElegidas: [],
            originLatCoodenades: '4.728071',
            originLongCoodenades: '-74.056078',
            estimacionesTiempos: [],
            puntosDeEntrega: [],
            habilitarGuardado: false,
            persona: {},
            drag: false,
            apiExecuted: false
        }
    },
    computed: {
        iframeSrc() {
            return `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3976.2414592171335!2d${this.originLongCoodenades}!3d${this.originLatCoodenades}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sco!4v1734621801911`;
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    watch: {
        plantillasElegidas: {
            handler(newVal) {
                this.puntosDeEntrega = newVal.map(point => ({
                    ...point,
                    fechaHoraInicio: new Date(),
                    fechaHoraEntrega: null,
                    tiempoDescarga: 'No estipulado',
                    estadoPuntoEntrega: 'No ha iniciado',
                    plantillaRutasId: point.id,
                    puntosEntregaGeneralesId: this.rutaIdObtener,
                    empresaId: this.persona.empresa.id,
                }));
            },
            immediate: true
        },
        zonaSeleccionada(newZona) {
            if (newZona) {
                const plantillasDeZona = this.plantillasRutas.filter(plantilla =>
                    plantilla.ubicacion.toLowerCase() === newZona.toLowerCase()
                );

                // Añadir nuevas plantillas sin duplicar
                plantillasDeZona.forEach(plantilla => {
                    if (!this.plantillasElegidas.some(p => p.id === plantilla.id)) {
                        this.plantillasElegidas.push(plantilla);
                    }
                });
            }
        }
    },
    async mounted() {
        this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
        await this.getPuntosEntregaData();
    },
    methods: {
        async saveData() {
            const form = this.$refs.form;
            if (!form.checkValidity()) {
                Swal.fire({
                    title: "Completa todos los campos requeridos",
                    text: "Antes de continuar, por favor completa los campos",
                    icon: "warning",
                    confirmButtonColor: "#186bdd",
                    confirmButtonText: "Entendido",
                });
                return;
            }
            try {
                const puntosEntrega = this.puntosDeEntrega.map((point) => ({
                    distanciaRecorrida: point.distanciaRecorrida,
                    tiempoRecorrido: point.tiempoRecorrido,
                    fechaHoraInicio: point.fechaHoraInicio,
                    fechaHoraEntrega: point.fechaHoraEntrega,
                    tiempoDescarga: point.tiempoDescarga,
                    lugarEntrega: point.lugarEntrega,
                    estadoPuntoEntrega: point.estadoPuntoEntrega,
                    plantillaRutasId: point.plantillaRutasId,
                    puntosEntregaGeneralesId: point.puntosEntregaGeneralesId,
                    empresaId: point.empresaId,
                }));

                console.log("Datos a registrar:", puntosEntrega);

                const results = await Promise.all(
                    puntosEntrega.map(async (punto) => {
                        try {
                            const response = await this.$store.dispatch("hl_post", {
                                path: "EntregasApp/CreatePuntosEntrega",
                                data: punto,
                            });

                            if (response.ok) {
                                const data = await response.json();
                                console.log("Punto de entrega registrado:", data);
                                return { success: true, data };
                            } else {
                                const errorData = await response.json();
                                console.error("Error en punto de entrega:", errorData);
                                return { success: false, error: errorData };
                            }
                        } catch (error) {
                            console.error("Error inesperado:", error);
                            return { success: false, error };
                        }
                    })
                );
                if (results.every(result => result.success)) {
                    Swal.fire({
                        title: "¡Éxito!",
                        text: "Todos los puntos de entrega se registraron correctamente.",
                        icon: "success",
                        confirmButtonColor: "#186bdd",
                        confirmButtonText: "Entendido",
                    });
                } else {
                    Swal.fire({
                        title: "Advertencia",
                        text: "Algunos puntos de entrega no se pudieron registrar. Por favor, revise los detalles e intente nuevamente.",
                        icon: "warning",
                        confirmButtonColor: "#186bdd",
                        confirmButtonText: "Entendido",
                    });
                }
            } catch (error) {
                console.error("Error crítico durante el registro:", error);
                Swal.fire({
                    title: "Error",
                    text: "Ocurrió un problema al procesar los registros. Inténtalo nuevamente.",
                    icon: "error",
                    confirmButtonColor: "#186bdd",
                    confirmButtonText: "Entendido",
                });
            }
        },
        actualizarFechaHoraEntrega(index, fecha) {
            this.puntosDeEntrega[index].fechaHoraEntrega = fecha;
        },
        removePuntoEntrega(index, event) {
            if (event) {
                event.preventDefault();
            }
            this.plantillasElegidas.splice(index, 1);
            this.$nextTick(() => {
                this.puntosDeEntrega = this.plantillasElegidas.map(point => ({
                    ...point,
                    fechaHoraInicio: new Date(),
                    fechaHoraEntrega: null,
                    tiempoDescarga: 'No estipulado',
                    estadoPuntoEntrega: 'No ha iniciado',
                    plantillaRutasId: point.id,
                    puntosEntregaGeneralesId: this.rutaIdObtener,
                    empresaId: this.persona.empresa.id,
                }));
            });
        },
        async getPuntosEntregaData() {
            try {
                const path = `EntregasApp/GetPlantillasRutasByEmpresa/${this.persona.empresa.id}`;
                const response = await this.$store.dispatch("get", { path });

                if (response && response.data) {
                    this.plantillasRutas = response.data;
                    this.zonas = [...new Set(this.plantillasRutas.map(plantilla => plantilla.ubicacion))];
                    console.log("Plantillas de entrega obtenidas:", this.plantillasRutas);
                    console.log("Zonas obtenidas:", this.zonas);
                } else {
                    console.error("Error al obtener las plantillas de entrega. Respuesta vacía.");
                    alert("Error al obtener las plantillas de entrega.");
                }
            } catch (error) {
                console.error("Hubo un error al hacer la solicitud:", error);
                alert("Hubo un error al obtener las plantillas de entrega.");
            }
        },
        async getUbicationApi() {
    Swal.fire({
        title: "¿Has terminado de validar las rutas?",
        text: "Si no has terminado de validar las rutas y su orden, no podrás cambiar el orden después.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#186bdd",
        cancelButtonColor: "#F2135D",
        confirmButtonText: "Sí!",
        cancelButtonText: "Cancelar",
    }).then(async (result) => {
        if (result.isConfirmed) {
            let currentDateTime = new Date();
            let originLat = this.originLatCoodenades;
            let originLong = this.originLongCoodenades;

            try {
                for (let i = 0; i < this.plantillasElegidas.length; i++) {
                    const destinationLat = this.plantillasElegidas[i].latUbicacionEntrega;
                    const destinationLong = this.plantillasElegidas[i].longUbicacionEntrega;

                    console.log(`Ruta ${i + 1}:`);
                    console.log(`Origen: ${originLat},${originLong}`);
                    console.log(`Destino: ${destinationLat},${destinationLong}`);

                    // IMPORTANTE: Mapbox usa `lng,lat` en lugar de `lat,lng`
                    const origin = `${originLong},${originLat}`;
                    const destination = `${destinationLong},${destinationLat}`;

                    const data = await this.$store.dispatch("hl_get", {
                        path: `EntregasApp/GetApiMapbox?origin=${origin}&destination=${destination}`
                    });

                    if (data.routes && data.routes.length > 0) {
                        const route = data.routes[0];

                        // Mapbox usa `distance` en metros y `duration` en segundos
                        const distance = (route.distance / 1000).toFixed(2) + " km"; // Convertimos metros a km
                        const durationInTraffic = Math.floor(route.duration / 60); // Convertimos segundos a minutos
                        const arrivalTime = new Date(currentDateTime.getTime() + route.duration * 1000);

                        console.log(`Distancia: ${distance}`);
                        console.log(`Duración estimada: ${durationInTraffic} minutos`);
                        console.log(`Hora estimada de llegada: ${arrivalTime.toLocaleTimeString()}`);

                        this.estimacionesTiempos.push({
                            distancia: distance,
                            duracionConTrafico: `${durationInTraffic} minutos`,
                            horaEstimada: arrivalTime.toLocaleTimeString(),
                        });

                        // Actualizar puntos de entrega
                        this.puntosDeEntrega = this.puntosDeEntrega.map((point, index) => ({
                            ...point,
                            distanciaRecorrida: this.estimacionesTiempos[index]?.distancia || null,
                            tiempoRecorrido: this.estimacionesTiempos[index]?.duracionConTrafico || null,
                        }));

                        // Actualizar la hora de referencia
                        currentDateTime = arrivalTime;
                        this.habilitarGuardado = true;
                    } else {
                        console.error(`Error en la solicitud para la ruta ${i + 1}:`, data);
                    }

                    // Mover el origen al destino para la siguiente iteración
                    originLat = destinationLat;
                    originLong = destinationLong;
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
            this.apiExecuted = true;
        }
    });
},
        limpiarTodasLasRutas() {
            this.puntosDeEntrega = [];
            this.plantillasElegidas = [];
            this.puntosDeZona = [];
            this.zonaSeleccionada = null;
            this.habilitarGuardado = false;
            this.apiExecuted = false;
        },
    }
}
</script>

<style scoped>
.selection-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.card-content {
    padding: 1.25rem;
    text-align: center;
}

.icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 0.75rem;
    transition: transform 0.3s ease;
}

.selection-card:hover .icon-wrapper {
    transform: scale(1.1);
}

.selection-card h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;
}

.selection-card:hover h3,
.selection-card.selected h3 {
    color: #007bff;
}

.selection-card.selected .icon-wrapper {
    background: linear-gradient(135deg, #0056b3, #4d0bd1);
}

.handle {
    cursor: move;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.flip-list-move {
    transition: transform 0.5s;
}

.timeline-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 60px;
}

.timeline-line {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #007bff;
    z-index: 1;
}

.point-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    position: relative;
    z-index: 2;
    transition: all 0.3s ease;
}

.point-number:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.point-number.disabled {
    cursor: default;
    background-color: #6c757d;
}

.route-point {
    position: relative;
    padding-left: 70px;
    margin-bottom: 20px;
}

.route-point:last-child .timeline-line {
    display: none;
}
</style>