<template>
    <form @submit.prevent="guardarnuevaRuta">
        <div class="row mt-4 align-items-start">
            <!-- Nombre de Ruta -->
            <div class="col-xl-6 mb-3">
                <label for="nombreRuta" class="form-label">
                    Nombre Ruta
                </label>
                <input v-model="nuevaRuta.nombreRuta" type="text" id="fechaHoraRuta" class="form-control" required
                    placeholder="El nombre de tu ruta">
            </div>
            <!-- Fecha y Hora de Ruta -->
            <div class="col-xl-6 mb-3">
                <label for="fechaHoraRuta" class="form-label">
                    Fecha y Hora Inicio Ruta
                </label>
                <input v-model="nuevaRuta.fechaHoraInicioRuta" type="datetime-local" id="fechaHoraRuta"
                    class="form-control" required>
            </div>
            <!-- Seleccionar placa -->
            <div class="col-xl-6 mb-3">
                <label for="fechaHoraRuta" class="form-label">
                    Seleccionar Placa
                </label>
                <v-select v-model="selectedPlaca" :options="placas" label="placa" placeholder="Seleccione un vehículo"
                    @input="getInfoVehiculoPorPlaca(selectedPlaca.placa)" />
                {{ selectedPlaca.placa }}
            </div>
            <!-- Descripción de ruta -->
            <div class="col-xl-12 mb-3 ">
                <label for="descripcionRuta" class="form-label">
                    Descripción de Ruta
                </label>
                <b-form-textarea v-model="nuevaRuta.descripcionRuta" id="descripcionRuta"
                    placeholder="Escriba una descripción..." rows="3" minlength="10" maxlength="550"
                    required></b-form-textarea>
            </div>
        </div>


        <!-- Botón Guardar -->
        <div class="d-flex justify-content-end">
            <vs-button :loading="isCreating" type="submit" animation-type="vertical">
                Guardar Punto de Ruta
                <template #animate>
                    <i class="fas fa-plus-circle"></i>
                </template>
            </vs-button>
        </div>
    </form>
</template>

<script>
import Swal from 'sweetalert2';
export default {
    name: 'CrearRutaModal',
    data() {
        return {
            isCreating: false,
            nuevaRuta: {
                fechaHoraInicioRuta: null,
                nombreRuta: '',
                descripcionRuta: '',
                placa: '',
                distanciaTotalRecorrida: 'Sin Registrar',
                tiempoTotalRecorrido: 'Sin Registrar',
                estadoRuta: 'No iniciada',
                conductorId: null,
                vehiculoId: null,
                empresaId: null,
            },
            placas: [],
            personas: [],
            plantillasRutas: [],
            selectedPlaca: '',
        }
    },
    async mounted() {
        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
        this.nuevaRuta.empresaId = this.usuario.empresa.id;
        await this.getPlacas(this.nuevaRuta.empresaId);
        await this.getPersonas(this.nuevaRuta.empresaId);
    },
    methods: {
        async getPlacas(empresaId) {
            try {
                console.log("Iniciando getPlacas para empresaId:", empresaId);
                let res = await this.$store.dispatch("hl_get", {
                    path: "Vehiculo/VehiculosPorEmpresa/" + empresaId,
                });
                console.log("Respuesta completa de getPlacas:", res);
                if (res && res.length > 0) {
                    this.placas = res;
                    console.log("Vehículos obtenidos:", this.placas);
                } else {
                    console.log("No se encontraron vehículos o la respuesta está vacía");
                    this.placas = [];
                }
            } catch (error) {
                console.error("Error en getPlacas:", error);
                this.placas = [];
            }
        },
        async getPersonas(empresaId) {
            try {
                console.log("Iniciando getPersonas para empresaId:", empresaId);
                let res = await this.$store.dispatch("hl_get", {
                    path: "Persona/ListPersonaByEmpresa/" + empresaId,
                });
                console.log("Respuesta completa de getPersonas:", res);
                if (res && res.length > 0) {
                    this.personas = res;
                    console.log("Personas obtenidas:", this.personas);
                } else {
                    console.log("No se encontraron personas o la respuesta está vacía");
                    this.personas = [];
                }
            } catch (error) {
                console.error("Error en getPersonas:", error);
                this.personas = [];
            }
        },
        async getInfoVehiculoPorPlaca(placa) {
            try {
                // Realizar la petición al endpoint
                let res = await this.$store.dispatch("hl_get", {
                    path: `Vehiculo/GetConductorModeloVehiculo/${placa}`,
                });

                console.log("ESTA ES LA RESPUESTA DEL ENPOINT DE LA PLACA", res)
                const data = await res;

                if (
                    (!data.informacionGeneral || data.informacionGeneral.length === 0) &&
                    (!data.conductoresAsignados || data.conductoresAsignados.length === 0)
                ) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Placa sin vehículo ni conductores asignados',
                        text: 'Esta placa no tiene conductores asignados ni un vehículo asignado. Para asignar la placa, diríjase al módulo de Super Transporte.',
                    });
                    return;
                }
                if (!data.informacionGeneral || data.informacionGeneral.length === 0) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Placa sin vehículo asignado',
                        text: 'Esta placa se encuentra sin un vehículo asignado. Para asignar la placa, diríjase a la hoja de vida del vehículo.',
                    });
                    return;
                }
                if (!data.conductoresAsignados || data.conductoresAsignados.length === 0) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Placa sin conductor asignado',
                        text: 'Esta placa no tiene un conductor asignado. Para asignar la placa, diríjase al módulo de Super Transporte.',
                    });
                    return;
                }
                this.nuevaRuta.conductorId = data.conductoresAsignados.at(-1).datosPersona.id;
                this.nuevaRuta.vehiculoId = data.vehiculo.id;
                console.log('Información del vehículo:', data);
                Swal.fire({
                    icon: 'success',
                    title: 'Datos encontrados',
                    html: `
                <p><strong>Vehículo:</strong> ${data.vehiculo.placa}</p>
                <p><strong>Último modelo asignado a la placa:</strong> ${data.informacionGeneral.at(-1).marca}</p>
                <p><strong>Último conductor asignado a la placa:</strong> ${data.conductoresAsignados.at(-1).conductorAsignado.nombresApellidosConductor.nombres + ' ' + data.conductoresAsignados.at(-1).conductorAsignado.nombresApellidosConductor.apellidos}</p>
            `,
                });

            } catch (error) {
                console.error('Error al obtener información del vehículo:', error);

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message || 'Ocurrió un error al consultar los datos del vehículo.',
                });
            }
        },


        async guardarnuevaRuta() {
            this.isCreating = true;
            try {
                // Asegurarse de que empresaId esté establecido
                this.nuevaRuta.placa = this.selectedPlaca.placa
                if (!this.nuevaRuta.empresaId) {
                    const usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
                    this.nuevaRuta.empresaId = usuario.empresa.id;
                }
                console.log("esta es la data a registrar en la ruta", this.nuevaRuta)

                const respuesta = await this.$store.dispatch("hl_post", {
                    path: "EntregasApp/CreatePuntosEntregaGenerales",
                    data: this.nuevaRuta
                });
                if (respuesta) {
                    this.$vs.notification({
                        progress: "auto",
                        icon: `<i class='fas fa-circle-check'></i>`,
                        color: 'success',
                        position: 'top-right',
                        title: 'Éxito',
                        text: '¡Ruta creada exitosamente!'
                    });
                    this.$emit('plantilla-ruta-agregada');
                    this.$bvModal.hide('modalPlantillaRuta');
                }
            }
            catch (error) {
                console.error("Error in guardarnuevaRuta:", error);
                alert("Ocurrió un error al guardar la ruta. Por favor, intente de nuevo.");
            } finally {
                this.isCreating = false;
            }
        },
        resetForm() {
            this.nuevaRuta = {
                fechaHoraInicioRuta: null,
                nombreRuta: '',
                descripcionRuta: '',
                placa: '',
                distanciaTotalRecorrida: 'Sin Registrar',
                tiempoTotalRecorrido: 'Sin Registrar',
                estadoRuta: 'No iniciada',
                conductorId: null,
                vehiculoId: null,
                empresaId: null,
            };
            this.selectedPlantilla = null;
        }
    }
}
</script>

<style scoped>
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>
